import * as React from 'react';
import AsideLeft from './Layout/AsideLeft';
import AsideRight from './Layout/AsideRight';
import NavMenu from './Layout/NavMenu';
import './Home.css';
import VerticallyCenteredModal from '../common/CustomModal/CustomModal';
import Cookies from 'js-cookie';
import RouteManagement from './RouteManagement/RouteManagement';
import { jsonDiff, hideContextMenu, initMapUtils, generalInitHome, toastOptions } from '../common/Utils';
import AreaManagement from './Area/AreaManagement';
import ImageAttachMode from './Map/ImageAttachMode';
import EmergencyMessage from './Terminal/EmergencyMessage'
import { webSocket } from "rxjs/webSocket";
import VCollapse from '../common/Collapse/VCollapse';
import HCollapse from '../common/Collapse/HCollapse';
import BottomTab from './Layout/BottomTab';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchPlace from '../common/SearchPlace/SearchPlace';
import TopTab from './Layout/TopTab';
import GlobalLoader from '../common/interceptorLoader';
import AudioRecord from './BottomTabItems/AudioRecord'
import * as signalR from '@microsoft/signalr';
import HomeLongPress from '../common/LongPress';
import ViewMode from './ViewMode/ViewMode';
import axiosInstance from '../common/interceptor';
import { HOST } from '../common/constant';
import eventBus from "../common/EventBus"
import AlertMap from './Layout/AlertMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

export default class Home extends React.Component<{}, HomeState>{
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = new HomeState();
        this.initMap = this.initMap.bind(this);
        this.onNavi = this.onNavi.bind(this);
        this.updateStateFunc = this.updateStateFunc.bind(this);
        this.onChildCallbackFunc = this.onChildCallbackFunc.bind(this);
        this.onEmergencyMessage = this.onEmergencyMessage.bind(this);
    }
    dataMap: any[] = [];
    private initMap = (homePosition: any) => initMapUtils(this, homePosition);

    private setHome() {
        axiosInstance.get(HOST() + "/Form/V1/Dat/map/setHome?homePosition=" + (Cookies.get('mapPos_t') || "{}")).then(() => {
            toast.success('ホームポジションを変更しました。', toastOptions);
        });
        hideContextMenu(true);
    }
    private connect: any

    public onNavi(path: any, data: any) {
        if (path == '/') {
            this.setState({ modalShow: false });
            if (data && data.target == 'routeList') {
                let right: any = this.refs.AsideRight;
                right.refs.RouteList.getListRoute(data.foucsId);
            }
            if (data && data.target == 'areaList') {
                let right: any = this.refs.AsideRight;
                right.getListArea(data.syncEnableOnly);
            }
            if (data && data.target == 'areaListRedraw') {
                let right: any = this.refs.AsideRight;
                right.redraw(data.area, data.listId);
            }
            if (data == 'terminalList') {
                let left: any = this.refs.AsideLeft;
                left.fullFetch();
            }
            if (data && data.target == 'audio') {
                if (data.action == 'startCall') {
                    this.setState({ audioRecord: true });
                    let AudioRecord: any = this.refs.AudioRecord;
                    AudioRecord.startTimer();
                }
                else if (data.action == 'endCall') {
                    let BottomTab: any = this.refs.BottomTab;
                    BottomTab.stopAudioCall(data);
                }
                else if (data.action == 'msgSentSuccess') {
                    let AudioRecord: any = this.refs.AudioRecord;
                    AudioRecord.onAudioMessageSent();
                }
                else if (data.action == 'completed') {
                    let AudioRecord: any = this.refs.AudioRecord;
                    AudioRecord.reset();
                    let BottomTab: any = this.refs.BottomTab;
                    BottomTab.onAudioMessageSent(data);
                    this.setState({ audioRecord: false });
                }
            }

            return;
        }

        if (path == '/bottomTab') {
            let BottomTab: any = this.refs.BottomTab;
            BottomTab.setActiveTab(data);
            return;
        }
        var hideHeader = (path == '/EditTerminalItem' || path == '/TerminalInformation');
        this.setState({
            modalShow: true,
            modalPath: path,
            modalData: data || {},
            modalHideHeader: hideHeader,
        });
        this.updateStateFunc({
            routeSearching: false,
            areaDrawing: false,
        });
    }

    componentDidMount() {
        var user: any = JSON.parse(Cookies.get('user') || "{}");
        if (user && !this.connect) {
            this.checkpitaVersion(user.pitaVersion, user.siteId, false);

            eventBus.on("changeDetailSetting", (data: any) =>
                this.checkpitaVersion(data.pitaVersion, user.siteId, true)

            );



            let connection = new signalR.HubConnectionBuilder()
            this.connect = connection.withUrl("/servernotify", {
                accessTokenFactory: () => user.token,
                transport: 4,
                logger: 6
            })
                .withAutomaticReconnect()
                .build();
            this.connect.on("ReceiveMessage", (title: any, data: any) => {
                const asideRight: any = this.refs.AsideRight;



                if (title == 'Emergency') {
                    const dataList = data.split('|');
                    if (dataList[0] != user.siteId) return;
                    const emergencyMessage: any = this.refs.EmergencyMessage;
                    emergencyMessage.onEmergencyMessageComming({
                        cliIDs: [dataList[1]],
                        cliNms: [dataList[2]],
                    })
                }
                else if (title == 'SyncArea' && user.siteId == data) {
                    asideRight.areaClickable(true);
                    this.onNavi('/', {
                        target: 'areaList',
                    });
                }
                else if (title == 'SyncAreaEnable' && user.siteId == data) {
                    asideRight.areaClickable(true);
                    this.onNavi('/', {
                        target: 'areaList',
                        syncEnableOnly: true
                    });
                }
                else if (title == 'SyncRoute' && user.siteId == data) {
                    this.onNavi('/', {
                        target: 'routeList',
                    });
                }
                else if (title == "GPSUPDATE") {
                    data = JSON.parse(data);
                    if (!data[user.siteId]) {
                        return;
                    }
                    data = data[user.siteId];
                    const left: any = this.refs.AsideLeft;
                    left.updatePosition(data);
                }
                else if (title == "UNITCOUNT_UPDATE") {
                    data = JSON.parse(data);
                    if (!data[user.siteId]) {
                        return;
                    }
                    data = data[user.siteId];
                    const TopTab: any = this.refs.TopTab;
                    TopTab.updateData(data);
                }

                else if (title == "WARNINGCOUNTUPDATE") {

                    if (Cookies.get('pv') === '2') {
                        // get alert data
                        axiosInstance.get(`${HOST()}/Form/V1/Dat/OperRec/GetWarningCount?sysID=${user.siteId}`).then((res) => {
                            if (res.data.data) {
                                this.setState({
                                    notResetMarker: false,
                                    warningData: res.data.data,
                                })
                            }
                        })
                    }

                }
            });
            const _this = this;
            this.connect.start().then(() => {
                axiosInstance.get(HOST() + "/Form/V1/Dat/map/registerOpen?connectId=" + this.connect.connection.connectionId).then((res) => {
                    if (res.data.data) {
                        generalInitHome(_this, res.data.data.homePosition);
                    } else {
                        const noMapAlert: any = _this.refs.noMap;
                        noMapAlert.style.display = 'block';
                    }
                })
            });
        }

        window.onbeforeunload = () => {
            this.connect.stop();
        }
    }

    private checkpitaVersion(pitaVersion: string, siteId: string, pvFlg: boolean) {
        if (pitaVersion) {
            this.setState({
                pitaVersion: pitaVersion
            });
            if (!Cookies.get('pv'))
                Cookies.set('pv', pitaVersion)
            if (pvFlg)
                Cookies.set('pv', pitaVersion)

            if (pitaVersion === '2') {
                // get alert data
                axiosInstance.get(`${HOST()}/Form/V1/Dat/OperRec/GetWarningCount?sysID=${siteId}`).then((res) => {
                    if (res.data.data) {
                        this.setState({
                            notResetMarker: false,
                            warningData: res.data.data,
                        })
                    }
                })
            }
        }
    }

    private updateStateFunc(data: any, options: any = {}) {
        const diff = jsonDiff("routeSearching", data);
        if (Object.keys(diff).length > 0) {
            this.setState(diff);
        } else {
            return;
        }
        const diff2 = jsonDiff("routeLinking", data);
        if (Object.keys(diff2).length > 0) {
            this.setState(diff2);
        } else {
            return;
        }

        const SearchPlace: any = this.refs.SearchPlace;
        const areaManagement: any = this.refs.AreaManagement;
        const routeManagement: any = this.refs.RouteManagement;
        const asideRight: any = this.refs.AsideRight;
        if (diff.areaDrawing) {
            this.setState({ routeSearching: false, imageAttachMode: false });
            if (options.areaImportPath) {
                areaManagement.clearAllState();
                areaManagement.drawAreaImportPath(options.areaImportPath);
            } else {
                areaManagement.turnOnDrawingMode();
            }
            routeManagement.turnOffSearchingMode();
            asideRight.areaClickable(false);
        } else {
            SearchPlace.clearAllMarker();
            areaManagement.turnOffDrawingMode();
            if (diff.routeSearching != undefined || data.forceArea) asideRight.areaClickable(true);
        }

        if (diff.routeSearching) {
            this.setState({ areaDrawing: false, imageAttachMode: false });
            areaManagement.turnOffDrawingMode();
            asideRight.areaClickable(false);
            if (options.routeImportPath) {
                routeManagement.drawRouteImportPath(options.routeImportPath);
            } else {
                routeManagement.turnOnSearchingMode();
            }
        } else {
            SearchPlace.clearAllMarker();
            routeManagement.turnOffSearchingMode();
            if (diff.routeSearching != undefined) asideRight.areaClickable(true);
        }
        if (diff2.routeLinking) {
            this.setState({
                notResetMarker: true
            })
        }
    }

    private onEmergencyMessage(data: any) {
        const diff = jsonDiff(this.state, data);
        const left: any = this.refs.AsideLeft;
        if (Object.keys(diff).length > 0) {
            var eTerminal = diff;
            // left.onEmergencyMessage(eTerminal);
            this.setState({ showAlert: true });
        }
        else {
            this.setState({ showAlert: false });
        }
        left.fetchData();
    }

    private onChildCallbackFunc(data: any) {
        const diff = jsonDiff(this.state, data);
        if (Object.keys(diff).length > 0) {
            // ImageAttachMode
            if ('funcIdx' in diff) {
                this.setState({ imageAttachMode: true, areaDrawing: false, routeSearching: false });
                const areaManagement: any = this.refs.AreaManagement;
                const routeManagement: any = this.refs.RouteManagement;
                routeManagement.turnOffSearchingMode();
                areaManagement.turnOffDrawingMode();

            }
            else if ('cancelable' in diff) {
                this.setState({ imageAttachMode: false });
                if (diff.data) {
                    // TODO:
                }
            }
            else if ('onUrgentMsgComming' in diff) {
                const emergencyMessage: any = this.refs.EmergencyMessage;
                emergencyMessage.onEmergencyMessageComming(diff.onUrgentMsgComming)
            }
        }
    }

    public logout() {
        let user = JSON.parse(Cookies.get('user') || "{}");
        var body = {
            loginID: user.username,
            password: '**********'
        };
        if (this.connect) {
            this.connect.stop();
            this.connect = null;
        }
        axiosInstance
            .post(HOST() + "/Form/V1/Mst/logout", body)
            .then((res: any) => {
                if (res.data.result) {
                    Cookies.remove('jwt');
                    Cookies.remove('user');
                    Cookies.remove('pv');
                    window.location.href = '/login';
                }
            });
    }

    copyToClipboard(e: any) {
        navigator.clipboard.writeText(e.target.dataset.latlon).then(() => {
            toast.success('緯度経度をコピーしました。', toastOptions);
        });
    }

    public render() {
        return (
            <div>
                <div ref="noMap" className="noMap" style={{ display: 'none' }}>
                    <span>
                        マップは、一度に 1 つのウィンドウにのみ表示できます。
                        <br />
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.logout()}>
                            <span>ログアウト</span>
                            <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
                        </button>
                    </span>
                </div>
                <GlobalLoader />
                <NavMenu onNavi={this.onNavi} updateStateFunc={this.onChildCallbackFunc} logOut={this.logout} />
                <VerticallyCenteredModal
                    show={this.state.modalShow}
                    hiddeHeader={this.state.modalHideHeader}
                    path={this.state.modalPath}
                    data={this.state.modalData}
                    onNavi={this.onNavi}
                    onHide={() => this.setState({ modalShow: false })}
                />

                <div className="bottomTab relative">
                    <TopTab
                        ref="TopTab"
                        map={this.state.map}
                        onNavi={this.onNavi}
                        bodyContainer={this.refs.main} />
                    <HCollapse
                        target={this.refs.TopTab}
                        collapseToDown={false}
                    />
                </div>
                <div className="d-flex main" ref="main" style={{ "height": "calc(((100vh - 52px) - 0px) - 0px)" }}>
                    <div className="relative h-100">
                        <AsideLeft
                            ref="AsideLeft"
                            onNavi={this.onNavi}
                            map={this.state.map}
                            updateStateFunc={this.onChildCallbackFunc}>
                        </AsideLeft>
                        <VCollapse target={this.refs.AsideLeft} collapseToRight={false} />
                    </div>

                    <div className="w-100 h-100">
                        <div className="main-container w-100 h-100" style={{ position: 'relative' }}>
                            <HomeLongPress map={this.state.map}>
                                <div className="w-100 h-100" id="map"></div>
                            </HomeLongPress>
                            {this.state.pitaVersion?.toString() === '2' && (<AlertMap dataAlert={this.state.warningData} />)}
                            <ViewMode map={this.state.map} />
                            <div className="route-management">
                                <RouteManagement
                                    onNavi={this.onNavi}
                                    map={this.state.map}
                                    ref="RouteManagement"
                                    routeSearching={this.state.routeSearching}
                                    routeLinking={this.state.routeLinking}
                                    notResetMarker={this.state.notResetMarker}
                                    updateStateFunc={this.updateStateFunc}
                                />
                            </div>
                            <div className="route-management image-attach-mode"
                                style={{ display: this.state.imageAttachMode ? 'inline-block' : 'none' }}>
                                <ImageAttachMode onNavi={this.onNavi} map={this.state.map} updateStateFunc={this.onChildCallbackFunc} />
                            </div>
                            <div className="area-management">
                                <AreaManagement
                                    ref="AreaManagement"
                                    onNavi={this.onNavi}
                                    map={this.state.map}
                                    areaDrawing={this.state.areaDrawing}
                                    updateStateFunc={this.updateStateFunc}
                                />
                            </div>
                            <div className="search-place-container">
                                <SearchPlace
                                    map={this.state.map}
                                    ref="SearchPlace"
                                    display={this.state.areaDrawing || this.state.routeSearching} />
                            </div>
                            <div className="emergency-message" style={{ marginLeft: '70px', display: this.state.showAlert ? 'inline-block' : 'none' }}>
                                <EmergencyMessage onEmergencyMessage={this.onEmergencyMessage} ref="EmergencyMessage" />
                            </div>
                            <div className="audio-record"
                                style={{ display: this.state.audioRecord ? 'inline-block' : 'none' }}>
                                <AudioRecord onNavi={this.onNavi} ref="AudioRecord" />
                            </div>
                        </div>
                    </div>

                    <div className="relative h-100">
                        <VCollapse target={this.refs.AsideRight} collapseToRight={true} />
                        <AsideRight
                            ref="AsideRight"
                            onNavi={this.onNavi}
                            map={this.state.map}
                            updateStateFunc={this.updateStateFunc}
                        ></AsideRight>
                    </div>
                </div>
                <div className="bottomTab relative">
                    <BottomTab
                        ref="BottomTab"
                        map={this.state.map}
                        onNavi={this.onNavi}
                        bodyContainer={this.refs.main} />
                    <HCollapse
                        target={this.refs.BottomTab}
                        collapseToDown={true}
                    />
                </div>
                <ToastContainer />
                <div id="mapContextMenu" className="mapContextMenu">
                    <div id="LatLonContext" onClick={(e: any) => this.copyToClipboard(e)} style={{ padding: '5px' }}>
                        ホームに設定
                    </div>
                    <div onClick={() => this.setHome()}>
                        ホームに設定
                    </div>
                </div>
            </div>
        );
    }

}

class HomeState {
    modalShow: boolean = false;
    modalHideHeader: boolean = true;
    modalPath: String = '';
    modalData: Object = {};
    routeSearching: boolean = false;
    routeImportPath: any = [];
    routeLinking: boolean = false;
    areaDrawing: boolean = false;
    map: any = undefined;
    imageAttachMode: boolean = false;
    audioRecord: boolean = false;
    showAlert: boolean = false;
    mapPos: any = {};
    pitaVersion: string = '0';
    warningData: any = {};
    notResetMarker: boolean = true;
}