import React from 'react';
import './AlertMap.css';

interface AlertMapProps {
  dataAlert: any;
}

const AlertMap: React.FC<AlertMapProps> = ({
  dataAlert
}) => {
  return (
    <>
      <div className="independent-operation-container">
        <div className="title">運行自主警戒時間超過台数</div>
        <div className="col-12">
          <div className="row">
            <div className="col-3 pt-1 text-center bg-warning">警告</div>
                      <div className="col-3 text-left p-1 bg-white">{dataAlert?.warningCastingCount || 0}</div>
            <div className="col-3 pt-1 text-center bg-danger">超過</div>
                      <div className="col-3 text-left p-1 bg-white">{dataAlert?.overCastingCount || 0}</div>
          </div>
        </div>
      </div>
      <div className="placement-limit-container">
        <div className="title">打設制限時間超過台数</div>
        <div className="col-12">
          <div className="row">
            <div className="col-3 pt-1 text-center bg-warning">警告</div>
                      <div className="col-3 text-left p-1 bg-white">{dataAlert?.warningTimeCount || 0}</div>
            <div className="col-3 pt-1 text-center bg-danger">超過</div>
                      <div className="col-3 text-left p-1 bg-white">{dataAlert?.overTimeCount || 0}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertMap;