export const HOST = () => {
    var url = window.location.href;
    // url = "http://pitaatto.eplatform.vn";
    var arr = url.split("/");
    return arr[0] + "//" + arr[2]
}

export const UNSET_OPTION = '';


// Directions API
// Maps JavaScript API
// Places API
export const API_KEY = 'AIzaSyBjb_zoxIWFKIa92maBbXYMhJtLWFPrD-w';

export const SETTING = {
    "MAP_CENTER_X": 139.76659754078753,
    "MAP_CENTER_Y": 35.681186550797065,
    "MAP_SCALE": 14
}

export const API_NAME = {
    STATIC_MAP: 'STATIC_MAP', // https://developers.google.com/maps/billing/gmp-billing#static-maps
    DIRECTION: 'DIRECTION', // https://developers.google.com/maps/billing/gmp-billing#directions
    PLACE: 'PLACE', // https://developers.google.com/maps/billing/gmp-billing#basic-data
}
