import * as React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import SiteManagement from './SiteManagement';
import UserManagement from './UserManagement';

export default class Admin extends React.Component<{
}, {
}> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
    }

    public render() {
		return (
            <div>
                <Tabs defaultActiveKey="user" id="adminTabs" mountOnEnter={true} unmountOnExit={true}>
                    <Tab eventKey="user" title="ユーザー">
                        <UserManagement />
                    </Tab>
                    <Tab eventKey="site" title="サイト">
                        <SiteManagement />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}
