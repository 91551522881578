import * as React from 'react';
import './NavMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import userImage from "../../assets/img/user.jpg";
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { isBlank } from '../../common/Utils';
import Logo from '../../common/Logo/Logo';
import axiosInstance from "../../common/interceptor";
import { HOST } from '../../common/constant';
import eventBus from "../../common/EventBus"

export default class NavMenu extends React.Component<{
    onNavi: Function,
    updateStateFunc: Function,
    logOut: any,
}, {
    isLogged: boolean,
    warningMessage: string,
    jobNo: string,
    pitaVersion: string
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLogged: false,
            warningMessage: '',
            jobNo: '',
            pitaVersion: '0'
        }
    }
    componentWillUnmount() {
        eventBus.remove("changeDetailSetting", null);
    }
    componentDidMount() {
        this.checkUserLogged()
        var accessToken = Cookies.get('jwt');
        var user: any = JSON.parse(Cookies.get('user') || "{}");

        if (Math.round(user.time) < 30) {
            this.setState({ warningMessage: `有効期限残り${Math.round(user.time)}日です。` })
        }
        this.setState({ jobNo: `No.${user.jobNo}` })

        this.checkPressureFeedSettingDisplay(user.pitaVersion);

        eventBus.on("changeDetailSetting", (data: any) =>
            this.checkPressureFeedSettingDisplay(data.pitaVersion)
        );
    }
    private checkPressureFeedSettingDisplay(pitaVersion: string) {
        if (pitaVersion) {
            this.setState({
                pitaVersion: pitaVersion
            });
        }
    }

    private checkUserLogged() {
        var logged = false;
        var jwt = Cookies.get('jwt');
        if (jwt === undefined) logged = false;
        else logged = !isBlank(jwt);
        this.setState({ isLogged: logged });
    }

    public async downloadManual() {
        const link: any = document.createElement("a");

        var accessToken: any = { 'authorization': Cookies.get('jwt') };
        const result = await fetch('/Form/V1/File/Manual', {
            method: 'get',
            headers: new Headers(accessToken)
        });

        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)

        link.download = 'マニュアル.pdf';
        link.href = href;
        link.click();
    }

    public render() {
        return (
            <header>
                <nav className="navbar navbar-expand-lg" id="navtop">
                    <span className="navbar-brand py-0">
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-menu" className="m-0">
                                <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                            </Dropdown.Toggle>
                            <Logo className="d-inline-block align-top" height={50} style="inline-flex" />
                            <Dropdown.Menu>
                                <DropdownButton drop="right" variant="link" id="dropdown-help" title="ヘルプ">
                                    <Dropdown.Item eventKey="1" onClick={() => this.downloadManual()}>
                                        マニュアル
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="2" onClick={() => this.props.onNavi('/version', { sizeFitContent: true })}>バージョン情報</Dropdown.Item>
                                    <Dropdown.Item eventKey="3" onClick={() => this.props.onNavi('/contractDetail', { sizeFitContent: true })}>契約内容</Dropdown.Item>
                                </DropdownButton>
                                <DropdownButton drop="right" variant="link" id="dropdown-setting" title="設定">
                                    <Dropdown.Item eventKey="1" onClick={() => this.props.onNavi("/DetailSetting", { sizeFitContent: true })}>詳細設定</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" onClick={() => this.props.onNavi("/SoundRegistration")}>音声登録</Dropdown.Item>
                                    <Dropdown.Item eventKey="3" onClick={() => this.props.onNavi("/CargoSetting", { sizeFitContent: true })}>積込み種別設定</Dropdown.Item>
                                    <Dropdown.Item eventKey="4" onClick={() => this.props.onNavi("/AccelerationWarningSetting", { sizeFitContent: true })}>加速度警告設定</Dropdown.Item>
                                    <Dropdown.Item eventKey="5" onClick={() => this.props.onNavi("/ProximityAlarmSetting", { sizeFitContent: true })}>近接警報設定</Dropdown.Item>
                                    <Dropdown.Item eventKey="6" onClick={() => this.props.onNavi("/EmailManagement")}>メールアドレス管理</Dropdown.Item>
                                    <Dropdown.Item eventKey="7" onClick={() => this.props.onNavi("/UnitCountSetting")}>台数カウント設定</Dropdown.Item>
                                    <Dropdown.Item eventKey="8" onClick={() => this.props.updateStateFunc({ funcIdx: 0 })}>マップ画像貼り付け</Dropdown.Item>
                                    <Dropdown.Item eventKey="9" onClick={() => this.props.onNavi("/AutoSendMessage")}>自動メッセージ送信</Dropdown.Item>

                                    {
                                        this.state.pitaVersion?.toString() === '2' && (
                                            <Dropdown.Item eventKey="9" onClick={() => this.props.onNavi("/PressurePumpingTimeSetting")}>コンクリート圧送時間設定</Dropdown.Item>
                                        )
                                    }

                                </DropdownButton>
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>
                    <div className="collapse navbar-collapse" id="navbarMain" style={{ display: 'block', flexBasis: 'unset' }}>
                        <div className="navbar-nav mr-auto">

                        </div >
                        <div className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
                            <span className="red">{this.state.warningMessage}</span>
                            <span className="mr-2" style={{ fontWeight: "bold" }}>{this.state.jobNo}</span>
                            <a href="#" onClick={() => this.props.onNavi('/accountInfoChange', { sizeFitContent: true })}
                            ><img src={userImage} width="35" height="35" className="border rounded-circle"
                                /></a>
                            <div className="ml-3">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => this.props.logOut()}>
                                    <span>ログアウト</span>
                                    <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
                                </button>
                            </div >
                        </div >
                    </div >
                </nav >
            </header >
        );
    }
}

// <Dropdown.Item eventKey="8" onClick={() => this.props.updateStateFunc({ funcIdx: 0 })}>マップ画像貼り付け</Dropdown.Item>